import React from 'react'
import ProfileCard from './fcomponents/ProfileCard'
import { useNavigate } from 'react-router-dom'

export default function Matches() {
	const navigate=useNavigate();
  return (
	<div className='container mx-auto md:p-4 p-2'>
		<h1 className='text-center font-semibold md:text-5xl text-3xl my-2.5 md:my-5 text-orange-500'>Matches</h1>
		<div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
			{Array.from({ length: 10 }, (_, index) => (
				<ProfileCard key={index} onClick={()=>navigate('/match/profile')}/>
			))}
		</div>
	</div>
  )
}
