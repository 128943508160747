import React from 'react'


const SingleInput =({className,label,onChange,type,isDisabled,value,onBlur,placeholder,isRequired}) => {
  return (
	<div className={className}>
		<label class="block mb-2 text-sm font-medium text-gray-900 ">{label}</label>
		<input 
		type={type}
        placeholder={placeholder}
		onChange={onChange}
		disabled={isDisabled}
		value={value}
		onBlur={onBlur}
		required={isRequired} class="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"/>
	</div>
  )
}

export default SingleInput;
