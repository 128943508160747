import { configureStore } from "@reduxjs/toolkit";
import themeReducer from './Theme'
import modalReducer from './Modal'
import userReducer from './User'
const store=configureStore({
	reducer:{
		theme:themeReducer,
		modal:modalReducer,
		user:userReducer
	}
})

export default store