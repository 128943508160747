import React, { useState } from 'react'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom'

const Navbar = ({user}) => {
    // State to manage the navbar's visibility
    const [nav, setNav] = useState(false);
    const navigate=useNavigate();

    // Toggle function to handle the navbar's display
    const handleNav = () => {
      setNav(!nav);
    };
  
    // Array containing navigation items
    const navItems = user?[
      { id: 1, text: 'Home',url: '/' },
      { id: 2, text: 'Login',url: '/login' },
      { id: 3, text: 'Resources',url: '/resource' },
      { id: 5, text: 'Contact',url: '/contact' },
    ] : [
      { id: 1, text: 'Home',url: '/' },
      { id: 2, text: 'Matches',url: '/matches' },
      { id: 3, text: 'Login',url: '/login' },
    ];

  return (
    <>
<div className='bg-white border-b border-gray-300 flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-black z-[100]'>
      {/* Logo */}
      <h1 className='w-full text-3xl font-bold text-orange-500  cursor-pointer' onClick={()=>navigate('/')}>Second Inning</h1>

      {/* Desktop Navigation */}
      <ul className='hidden md:flex'>
        {navItems.map(item => (
          <li 
            onClick={()=>navigate(item?.url)}
            key={item.id}
            className='px-4 py-1 hover:bg-orange-500 hover:text-white rounded-xl m-2 cursor-pointer duration-300'
          >
            {item.text}
          </li>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? 'fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-white ease-in-out duration-500 z-[100]'
            : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] z-[100]'
        }
      >
        {/* Mobile Logo */}
        <h1 className='w-full text-3xl font-bold text-orange-500 m-4'>REACT.</h1>

        {/* Mobile Navigation Items */}
        {navItems.map(item => (
          <li
            onClick={()=>navigate(item?.url)}
            key={item.id}
            className='p-4 border-b rounded-xl hover:bg-orange-500 duration-300 hover:text-white cursor-pointer border-gray-600'
          >
            {item.text}
          </li>
        ))}
      </ul>
    </div>
    
    </>
  )
}

export default Navbar