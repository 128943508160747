import axios from 'axios';
import { BASE_URL } from './Constant';

const axiosInstance = axios.create({
	baseURL: BASE_URL,
});


axiosInstance.interceptors.request.use(
  config => {
    const accessToken=JSON.parse(localStorage.getItem("accessToken"))
    // Exclude Authorization header for certain API calls (e.g., login, register)
    if (!config.url.endsWith('/login') && !config.url.endsWith('/register')) {
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.setItem('isLoggedIn',false)
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;