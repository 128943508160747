import React from "react";
import success1 from "./fimages/success1.jpg";
import success2 from "./fimages/success2.jpg";
import success3 from "./fimages/success3.jpg";
import success4 from "./fimages/sanika.jpg";
import success5 from "./fimages/success5.jpg";
import success6 from "./fimages/success6.jpg";

const cardData = [
  {
    image: success1,
    title: "Gopal & Surekha",
    description: "Thanks to Second Inning for connecting us",
  },
  {
    image: success2,
    title: "Anand & Anandi",
    description: "Thank you for helping me find the love of my life.",
  },
  {
    image: success3,
    title: "Basavaraj & Anu",
    description: "We are grateful for Second Inning as role in our story",
  },
  {
    image: success4,
    title: "Vikas & Arpita",
    description: "I found my true Love. Thanks to Second Inning",
  },
  {
    image: success5,
    title: "Advik & Rohini",
    description: "Here ends my search for true love. Thanks to Second Inning",
  },
  {
    image: success6,
    title: "Rahul & Kajal",
    description: "I found my soulmate on Second Inning.",
  },
];

const Card = ({ image, title, description }) => {
  return (
    <div className="w-[30rem] h-[45rem] border">
      <img className="w-full h-3/4 object-cover" src={image} alt={title} />
      <div className="w-full h-1/4 flex items-center justify-center flex-col gap-5">
        <h5 className=" text-center font-semibold text-xl">{title}</h5>
        <p>{description}</p>
      </div>
    </div>
  );
};
const Success = () => {
  return (
    <>
      <div className="container" id="success">
        <h1 className="text-center my-4 font-semibold text-4xl leading-normal">
          Success Stories
        </h1>

        <div className=" container flex items-center justify-evenly flex-wrap gap-4">
          {cardData.map((card, index) => (
            <Card
              key={index}
              image={card.image}
              title={card.title}
              description={card.description}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Success;
