
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';


import Frontend from './components/Frontend';
import Signup from './components/Signup';
import Login from './components/Login';
import Portfolio from './components/fcomponents/Portfolio';
import Forget from './components/Forget';
import Reset from './components/Reset';
import Navbar from './components/fcomponents/Navbar';
import Footer from './components/fcomponents/Footer';
import Register from './components/Register';
import { useSelector } from 'react-redux';
import Matches from './components/Matches';
import MatchProfile from './components/MatchProfile';

function App() {
  const user=useSelector(state=>state.user.isLoggedIn);

  return (
    <>
      <Navbar user={user}/>
        <div className=''>
          <Routes>
            <Route path="/" element={<Frontend  title="Second Inning" />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forget-password" element={<Forget />} />
            <Route path="/reset-password" element={<Reset />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/matches" element={<Matches />} />
            <Route path="/match/profile" element={<MatchProfile />} />
          </Routes>
        </div>
      <Footer/>
    </>
  );
}

export default App;
