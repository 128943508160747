import { createSlice } from "@reduxjs/toolkit";

const initialState={
	isLoggedIn:JSON.parse(localStorage.getItem('isLoggedIn')),
	userProfile:{}
}
const userSlice=createSlice({
	name:'user',
	initialState,
	reducers:{
		userLogin:(state,actions)=>{
			state.isLoggedIn=true;
			state.userProfile=actions.payload;
		},
		userLogout:(state)=>{
			state.isLoggedIn=false;
			state.userProfile={};
			localStorage.setItem('isLoggedIn',false)
		},
	}
})

export const {userLogin,userLogout}=userSlice.actions
export default userSlice.reducer