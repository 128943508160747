import React from "react";

export default function MainButton({ title, type, onClick }) {
  return (
    <button
      onClick={onClick}
      type={type}
      class="w-full text-white bg-orange-400 hover:bg-orange-500 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center 600 "
    >
      {title}
    </button>
  );
}
