import React from "react";

export default function MatchProfile() {
  return (
    <div class="container mx-auto md:p-4 p-2">
      <div class="md:flex no-wrap md:-mx-2">
        <div class="w-full md:w-3/12 md:mx-2">
          <div class="bg-white p-3 border-t-4 border-orange-500">
            <div class="image overflow-hidden">
              <img
                class="h-auto w-full mx-auto"
                src="https://images.pexels.com/photos/1689731/pexels-photo-1689731.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                alt="Jane Doe"
              />
            </div>
            <h1 class="text-gray-900 font-bold text-xl leading-8 my-1">
              Jane Doe
            </h1>
            <h3 class="text-gray-600 font-lg leading-6">
              Owner at Her Company Inc.
            </h3>
            <p class="text-sm text-gray-500 leading-6">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
              Reprehenderit, eligendi dolorum sequi illum qui unde aspernatur
              non deserunt.
            </p>
            <ul class="bg-gray-100 text-gray-600 py-2 px-3 mt-3 rounded shadow-sm">
              <li class="flex items-center py-3">
                <span>Status</span>
                <span class="ml-auto">
                  <span class="bg-green-500 py-1 px-2 rounded text-white text-sm">
                    Active
                  </span>
                </span>
              </li>
              <li class="flex items-center py-3">
                <span>Member since</span>
                <span class="ml-auto">Nov 07, 2016</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="w-full md:w-9/12 mx-2">
          <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span clas="text-green-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
				  className=" fill-orange-500"
                  width="20"
                  height="20"
                >
                  <path d="M224 256C294.695 256 352 198.691 352 128S294.695 0 224 0C153.312 0 96 57.309 96 128S153.312 256 224 256ZM274.664 304H173.336C77.609 304 0 381.602 0 477.332C0 496.477 15.523 512 34.664 512H413.336C432.477 512 448 496.477 448 477.332C448 381.602 370.398 304 274.664 304Z" />
                </svg>
              </span>
              <span class="tracking-wide">About</span>
            </div>
            <div class="text-gray-700">
              <div class="grid md:grid-cols-2 text-sm">
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">First Name</div>
                  <div class="px-4 py-2">Jane</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Last Name</div>
                  <div class="px-4 py-2">Doe</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Gender</div>
                  <div class="px-4 py-2">Female</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Contact No.</div>
                  <div class="px-4 py-2">+11 998001001</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Current Address</div>
                  <div class="px-4 py-2">Beech Creek, PA, Pennsylvania</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Permanent Address</div>
                  <div class="px-4 py-2">Arlington Heights, IL, Illinois</div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Email</div>
                  <div class="px-4 py-2">
                    <a class="text-main-color" href="mailto:jane@example.com">
                      jane@example.com
                    </a>
                  </div>
                </div>
                <div class="grid grid-cols-2">
                  <div class="px-4 py-2 font-semibold">Birthday</div>
                  <div class="px-4 py-2">Feb 06, 1998</div>
                </div>
              </div>
            </div>
          </div>


		  <div class="bg-white p-3 shadow-sm rounded-sm">
            <div class="flex items-center space-x-2 font-semibold text-gray-900 leading-8">
              <span clas="text-green-500">
				<svg xmlns="http://www.w3.org/2000/svg" className=" fill-orange-500" width="20"
                  height="20" viewBox="0 0 512 512"><path d="M256 16C123.449 16 16 123.508 16 256C16 388.578 123.449 496 256 496S496 388.578 496 256C496 123.508 388.551 16 256 16ZM256 128C273.674 128 288 142.326 288 160C288 177.672 273.674 192 256 192S224 177.672 224 160C224 142.326 238.326 128 256 128ZM296 384H216C202.75 384 192 373.25 192 360S202.75 336 216 336H232V272H224C210.75 272 200 261.25 200 248S210.75 224 224 224H256C269.25 224 280 234.75 280 248V336H296C309.25 336 320 346.75 320 360S309.25 384 296 384Z"/></svg>
              </span>
              <span class="tracking-wide">More Information</span>
            </div>
				<p className="">Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem repellendus illum sunt cupiditate alias nulla, laboriosam aliquam reprehenderit nobis unde doloremque ab, harum quasi ratione mollitia dolorum. Deleniti, in magni?
				Dolores a unde aliquid accusamus culpa placeat beatae iusto. Enim culpa reprehenderit repellat cumque amet vero error dicta! Facilis vero voluptate quae sunt quaerat blanditiis eligendi voluptas, alias deserunt! Saepe?
				Earum maiores dignissimos, adipisci quidem id nisi ducimus numquam quo deleniti est necessitatibus quia accusamus similique voluptatem harum velit. Ducimus animi fugit ipsa illum tempore voluptate accusamus recusandae vitae laboriosam.
				Saepe, voluptates, voluptatibus vitae numquam assumenda nihil quibusdam recusandae id nisi officia praesentium iure at, a illo consequatur iste beatae quos! Expedita quis laborum rerum corporis illo error assumenda ratione.
				Blanditiis optio distinctio, error qui modi officia aspernatur deserunt ullam ea unde fuga explicabo, perspiciatis dolores libero itaque quisquam excepturi molestiae? Officia, officiis. Harum, expedita repudiandae minima veniam consequatur fugiat!
				Eveniet placeat, aliquam unde illo error minus numquam, adipisci delectus necessitatibus sapiente pariatur ab magni commodi consectetur! Corporis quibusdam earum mollitia. Recusandae libero tempore amet minus expedita nesciunt consequuntur ipsum.
				Aspernatur eum nobis provident amet, quis facilis alias debitis excepturi nemo voluptate illo quam magni asperiores architecto laboriosam magnam ab reprehenderit est, corrupti ullam earum labore! Atque natus at tempore?
				Cumque deserunt quo cum repudiandae repellendus, quibusdam eveniet facilis quasi at, facere ad voluptate cupiditate corporis, nobis illum ea maiores nemo rem consequatur est quia rerum saepe! Assumenda, vitae tempora.
				Quam nemo praesentium quasi iste distinctio quidem ut ea delectus sit consectetur corrupti ullam laborum explicabo ipsa expedita, doloribus libero dolorem quisquam debitis rerum at recusandae cupiditate tempora. Perspiciatis, ad?
				Possimus dolor assumenda, sequi repudiandae aspernatur, voluptates sunt magnam sapiente a ab dolorum vel ducimus incidunt provident animi. Enim, nihil vitae odit nesciunt delectus dolorem officiis non possimus tempora facere.</p>
          </div>
        </div>
      </div>
    </div>
  );
}
