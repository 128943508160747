import React from "react";

import "../App.css";
import { Link } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import FormInput from "./Form/FormInput";
import MainButton from "./Button/MainButton";

const Login = () => {

    const formSchema = z.object({
        phone_no: z
          .string()
          .nonempty("Phone number is required")
          .regex(/^[0-9]{10}$/, "Phone number must be 10 digits long"),
        
        password: z
          .string()
          .nonempty("Password is required")
          .min(8, "Password must be at least 8 characters long")
          .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
          .regex(/[a-z]/, "Password must contain at least one lowercase letter")
          .regex(/\d/, "Password must contain at least one number")
          .regex(/[^a-zA-Z0-9]/, "Password must contain at least one special character"),
      });

  const { control, handleSubmit } = useForm({
    defaultValues: {
        phone_no: "",
        password: "",
    },
    resolver: zodResolver(formSchema),
  });

  const onSubmit = () => {

  };

  return (
    <section class="bg-gray-50 ">
      <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div class="w-full bg-white rounded-lg shadow  md:mt-0 sm:max-w-md xl:p-0  ">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
              Sign in to your account
            </h1>
            <form
              class="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormInput
                label={'Phone Number'}
                type={'number'}
                placeholder={'Enter phone number'}
                name={'phone_no'}
                control={control}
              />
              <FormInput
                label={'Password'}
                type={'password'}
                placeholder={'Enter password'}
                name={'password'}
                control={control}
              />

              <MainButton type={'submit'} title={'submit'}/>
              <p class="text-sm font-light text-gray-500 -gray-400">
                Don’t have an account yet?{" "}
                <Link
                  to={"/register"}
                  class="font-medium text-primary-600 hover:text-orange-400 hover:underline -primary-500"
                >
                  Sign up
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
