import React from 'react'

export default function ProfileCard({onClick}) {
  return (
	<div class="w-full sm:w-72 md:w-60 lg:w-80 xl:w-[390px]  2xl:w-[420px] bg-white rounded-lg shadow-lg overflow-hidden">
        <div class="relative cursor-pointer" onClick={onClick}>
            <img src="https://placehold.co/1920x1080" alt="Profile Image" class="w-full h-52 object-cover"/>
        </div>
        <div class="p-4 cursor-pointer" onClick={onClick}>
            <h1 class="text-lg font-bold">Emma Rose, 25</h1>
            <p class="text-gray-600 text-sm mt-1 flex items-center"><i class="fas fa-briefcase mr-2"></i> Product Designer at ABC Pvt. Ltd.</p>
            <p class="text-gray-600 text-sm mt-1 flex items-center"><i class="fas fa-graduation-cap mr-2"></i> B.Des., ABC Design School</p>
            <p class="text-gray-600 text-sm mt-1 flex items-center"><i class="fas fa-map-marker-alt mr-2"></i> Bangalore</p>
        </div>
        <div class="px-4 py-2 cursor-pointer" onClick={onClick}>
            <h2 class="text-md font-semibold">About</h2>
            <p class="text-gray-600 text-sm mt-2">I love photography with a passion in design and music, along with a deep interest in astrophysics. ANIMATION & GRAPHIC DESIGN are the areas which fascinate me the most!!! All interests of mine, what I believe, helps me to relax from my otherwise monotonous schedule!</p>
        </div>
        <div class="flex justify-around p-4">
            <button class="flex items-center justify-center w-12 h-12 rounded-full bg-red-500 text-white text-xl">
                <i class="fas fa-times"></i>
            </button>
            {/* <button class="flex items-center justify-center w-12 h-12 rounded-full bg-purple-500 text-white text-xl">
                <i class="fas fa-star"></i>
            </button> */}
            <button class="flex items-center justify-center w-12 h-12 rounded-full bg-orange-500 text-white text-xl">
                <i class="fas fa-heart"></i>
            </button>
        </div>
    </div>
  )
}
